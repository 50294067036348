/* eslint-disable max-len */

export interface IJsonWebKey {
  alg: string;
  e: string;
  kid: string;
  kty: 'RSA';
  n: string;
  use: string;
}
export interface IUserPoolConfig {
  [key: string]: {
    env: string;
    keys: IJsonWebKey[];
  };
}

/**
 * Cognitp user pool configuration and JSON Web Key sets
 *
 * @see https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-tokens-verifying-a-jwt.html
 */
export const userPoolConfig: IUserPoolConfig = {
  'eu-west-1_Hroyrm1mH': {
    env: 'dev',
    // Downloaded from https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_n3VzTh1TV/.well-known/jwks.json
    keys: [
      {
        alg: 'RS256',
        e: 'AQAB',
        kid: 't48tEz9s2MCKJNPCAP1k8miNMeqaOzo8hNo+f0yGN4g=',
        kty: 'RSA',
        n: 's0KTRJst6DtR5VGAuIKe3D8qYDMrjF2aTU79LKuys_ZCaMIJzbYwPyfqWEzjIHfUHfdb19UBx93Vb_vvOHRjWY6bNBMCWByjrYNv7V-yfZ6HkycINit2b9_aMxiCWShMnfKE3NO61JK9D4BK10Cgd6dlq4takdyk28obhDYa5aDKx2_bBGziI4RFLSiiaUHz48igeucO60ioJWWALmMxnJ1uVWUq1z6k2vlT2q33idD5JqLf82YaaiAsB8d5IK68rXE1Z0eBeJI4xfp-SAF-I-0haBp8S9B5jhlT4bIV_YYkqMfw8daU8pYQcGzzRcfzLMpJw9a5EKe_z28kt-4s9Q',
        use: 'sig',
      },
      {
        alg: 'RS256',
        e: 'AQAB',
        kid: 'BH68RnDuPyqpube1OVTFgF5uoX3mJeMZszz4CKY9IVk=',
        kty: 'RSA',
        n: '8GytFkl0V1LQy_kBeMqP848VZ0uqTEC140Toei4w_9utZcnu2reSiMntaCODAT1B2a2Je4y8IhZ-uXqEG1ooZqxmfrkh6ojVRyju3EjONyPwnLg1V_Jjv-zWdtrRad6H-Ow1eYRLmGh291wovUSp_NFeBeFH9Mbckd5zQ82OJupOc2gwjH2doUhn8unce3KWOFEUnahfg4GjAIKLK2ZO5QwD9r437tYEmPbC4rzWKzE7TUfR3aM8lNd81-cosPXD-kTEh81NqOaoplJujpZ3Z1m9xWPYThTUmNXEfP8tlAFPLykjoMB69tD4igJrALbJuj3ggGB_0WnN4tE26iPnAQ',
        use: 'sig',
      },
    ],
  },
  'eu-west-1_lTjXyG5sf': {
    env: 'stage',
    // Downloaded from https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_lTjXyG5sf/.well-known/jwks.json
    keys: [
      {
        alg: 'RS256',
        e: 'AQAB',
        kid: 'UcjE294glo3swfolM++dJdCQOIFsdNXjXnmviWQ+HAM=',
        kty: 'RSA',
        n: '72aGeWwknQgoQ8XQcYqctL_ZFHweTBvJ6vyDWgA5y8XSiwEX4-Y7KA3Fge-usVBNg0f6orwePn7ZxBELP7ypd1ZwOoFROkfLKg6eKBnpffiotLuBSgQ5VWmI3doKmreHHcFwVbqU8jcYx2zH4JkwJKRqhVQuTjYzInHuNZlmpeujTzcGaZF-zhORPcu2fm7wD70DsW_wyvcJRiefgTGoRkfurQeA_gQxwqMbsP2kr1Wlzxi21FH_3EVEdpXZkm7Tot2Bzsu5eDBPIachLr8hlduozSf3bwPCvsBLo_wltuSMlriCFu-zO6DlMTDQR6IvyIJaFVOQDPjo537xRCHu3Q',
        use: 'sig',
      },
      {
        alg: 'RS256',
        e: 'AQAB',
        kid: '+Z04HZdM9hrgNIU2+udqD72WsU2JFFTIjIDHhDn7Vzs=',
        kty: 'RSA',
        n: '09xJxIJbycR8aOEvuzmBIKMRnMXU7fXxLR3JSPgtF8LZEwME1KCksKdwPZPYtJKDsCgY4I8i3OeeGD4tpz2dhmLhhIbZsWm0MXUezTxl44smVPH3_ehMoy4pxfRD6ZOo5Ichc18tgIzq6RCsvxEBi2Q4NMAnf_4JgxXZw7tVC3FiAeVKjlqfmYzDY01gCeK4Z-51eh0EJcSphddDOAK4Ve7cvIr0O2LqCNlKoEoRTdZTxrzbiqhuN_ennEUsWvc3lPRmAqGmYL4tbu7rTxMPbUdrQPwhqA0diMUPh-p7TzGqjt_OHhV2h_8JywTkcyzQjKggk-CZxTicETiCvQ-j0w',
        use: 'sig',
      },
    ],
  },
  'eu-west-1_nmPHsyt4o': {
    env: 'prod',
    // Downloaded from https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_nmPHsyt4o/.well-known/jwks.json
    keys: [
      {
        alg: 'RS256',
        e: 'AQAB',
        kid: '0VrDS0ff9Azxdd84Iu8C4jwFVHRfZFAdhZZ6G1+ZBto=',
        kty: 'RSA',
        n: '0Wqs41eLsAQqtsRl9DZBfh2y7z2Uqv59Gt1poszXcI7mH_JGQZ8xOlw5DuCyef8p0v_EYPcMdK7EpxcO2-QlDyob5qxMgwaCfIJrZaD3RdjbY7xo9ZB8PrWAPBB127osu2acAuaKri7dcPXR28U_oJ8NXhiINkDi4CdIkSzp6yiMb3tNUaYEXg_B4xsS-216bDJyww16hFdz-uVhAugBRXQ77uOj6d-p8MuWt3HdA_OsKPiJNvQbXDrzDMdkWa2xpj0WYfBNHGTJCoXnU2rHyN8MbqgNlIS2-aALdeizKuLwWb4YXOGJv1Eii0LZriLLQjXRMV82BQ1Kb7_AdT4DgQ',
        use: 'sig',
      },
      {
        alg: 'RS256',
        e: 'AQAB',
        kid: '8we8yGoy/5uRo1VEk3JRJ1m92n3QoNcyIueh+2I0Vy8=',
        kty: 'RSA',
        n: '1X6nmPQokCxqbnkzI_r5kIZnG-Bv_rUYXZFMhivY_FqCVzDM4GEr-GIc-pdqMEKWX-Gqw3iygtXS3aF5OgSkV8KMeQ5VRViNHKb97onW-GzipbUNCi_2aE72u6RwlLlu5w_sAkXQsYObMbzFrK9U9zQp9ffsF9Pc_yWC1qj1ryDCUX-l75506gqM8_XpyP3hAtoK1o1FckeZpgB4MnAd289Z5KubR6oRiShr8JAjTUsGYjSTL5p4SqYUD5FbnDqV7i3jNZJK3AIwOO5GjJ_mE7bCCyLOLu3sA46vg2vnrHSFEsQJkUe6hGS9sj06umGtAG8gKeN5n7nW0Ma_TubuRw',
        use: 'sig',
      },
    ],
  },
};
